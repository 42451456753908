var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"overlay-opacity":"0.2","max-width":"400px"},model:{value:(_vm.solveModal),callback:function ($$v) {_vm.solveModal=$$v},expression:"solveModal"}},[_c('v-card',[_c('v-toolbar',{staticClass:"text-h5",attrs:{"color":"primary","dark":""}},[_vm._v(" Solve "+_vm._s(_vm.clickedSolve.name)+" ")]),_c('div',{staticClass:"ma-2 pa-2"},[_c('p',{staticClass:"text-h6"},[_vm._v(" Time: "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.clickedSolve.time))])]),_c('p',{staticClass:"text-h6"},[_vm._v(" Type: "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.clickedSolve.scrambleType))])]),_c('p',{staticClass:"text-h6"},[_vm._v(" Scramble: "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.clickedSolve.scramble ? _vm.clickedSolve.scramble : "")+" ")])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.solveModal = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 enable-scroll",class:{
      'phone-table-height': _vm.isPhone,
      'tablet-table-height': _vm.isTablet,
      'desktop-table-height': _vm.isDesktop
    },style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background }),attrs:{"headers":_vm.headers,"items":_vm.currentTimes,"items-per-page":1000,"sort-by":_vm.sortBy,"sort-desc":_vm.tableSortProp,"hide-default-header":"","hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.tableSortProp=$event},"update:sort-desc":function($event){_vm.tableSortProp=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"font-weight-bold mb-0 ml-1"},[_vm._v(_vm._s(item.name))])]}},{key:"item.time",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"font-weight-bold mb-0 pointer",on:{"click":function($event){return _vm.openSolveModal(item)}}},[_vm._v(" "+_vm._s(item.time)+" ")])]}},{key:"item.remove",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"aria-label":"close","text":"","icon":"","medium":"","color":"red darken-2"},on:{"click":function($event){return _vm.removeTime(item.session, item.name)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.closeIcon)+" ")])],1)]}},{key:"item.plusTwo",fn:function(ref){
    var item = ref.item;
return [(_vm.currentSessionTimes[item.name - 1].plusTwo)?_c('h4',{staticClass:"danger-text",class:{
          'disable-cursor': _vm.currentSessionTimes[item.name - 1].plusTwo
        }},[_vm._v(" +2 ")]):_c('h4',{staticClass:"font-weight-regular pointer",class:{
          'disable-cursor': _vm.currentSessionTimes[item.name - 1].dnf
        },on:{"click":function($event){return _vm.plusTwo(item.session, item.name)}}},[_vm._v(" +2 ")])]}},{key:"item.dnf",fn:function(ref){
        var item = ref.item;
return [(_vm.currentSessionTimes[item.name - 1].dnf)?_c('h4',{staticClass:"danger-text",class:{
          'disable-cursor': _vm.currentSessionTimes[item.name - 1].dnf
        }},[_vm._v(" DNF ")]):_c('h4',{staticClass:"font-weight-regular pointer",class:{
          'disable-cursor': _vm.currentSessionTimes[item.name - 1].dnf
        },on:{"click":function($event){return _vm.dnf(item.session, item.name)}}},[_vm._v(" DNF ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }